<template>
<div>
    <div class="paymentContainer" 
        :style="{
            top: scrollTop + 'px',
            display: paymentCenterShow ? 'block' :'none'
        }">
      <payment-center
        ref="paymentCont"
        :paymentForm="paymentForm"
        :needPaymentMusicId="transferPaymentMusicId"
        :musicName="transferMusicName"
        @closePaymentCenter="closePaymentCenter"
        @payAudioLoadingOver="payAudioLoadingOver"
        @queryPaymentHistory="queryPaymentHistory"
        @setCurrentMusicLicense="setCurrentMusicLicense"
        >
      </payment-center>
    </div>
    <div v-if="changeHeadTip" class="headUrl-content">
        <div class="overlay-background"></div>
        <div class="headContent">
            <div class="headContent-title">
                <span>设置头像</span>
                <span class="close-overlay" @click="closeContent">×</span>
            </div>
            <div class="headContent-image">
                <div class="user-image">
                    <img :src="userHeadUrlArr[userHeadUrlIndex]"/>
                    <p>预览</p>
                </div>
                <div class="headContent-imageArr">
                    <img v-for="(item,index) in userHeadUrlArr" :key="index" 
                        :style="userHeadUrlIndex == index ? 
                        'border: 2px solid #5490F4;' : ''"
                        :src="item" 
                        @click="chooseHeadUrl(index)"/>
                </div>
            </div>
            <div class="button-content">
                <span class="cancel"  @click="closeContent">取消</span>
                <span class="determine" @click="determineHeadUrl">确定</span>
            </div>
        </div>
    </div>
    <div class="user-container"  @click="queryClick($event)">
        <div class="user-title">
            个人中心
        </div>
        <div class="user-aboutAudioNum">
            <div class="user-information">
                <div class="UserHeadUrl">
                    <img @click="openContent" :src="userHeadUrl"/>
                </div>
                <div class="userNickname" :class="userVipSort == 0 ? 'maxTop' : 'minTop'">
                    <div class="userNickname-model" v-if="!changeNickNameTip">
                        <div class="p">{{nickname}}</div>
                        <i class="iconfont icon-icon_input_24-copy"
                        @click="getFocus"></i>
                    </div>
                    <div class="userNickname-input" v-if="changeNickNameTip">
                        <input v-model="nickname" ref="input" v-on:keyup="getEnter($event)" @blur="changeUserName"/>
                        <i class="iconfont icon-icon_input_24-copy"
                            @click="getFocus"></i>
                    </div>
                    <div class="userSort" v-if="userVipSort == 1" style="background:#2FAA4A;">创作者</div>
                    <div class="userSort" v-if="userVipSort == 2" style="background:#4C68FB;">专业创作者</div>
                    <div class="userSort" v-if="userVipSort == 9" style="background:#FBB54C;">企业会员</div>
                </div>
            </div>
            <div class="vipContainer">
                <div class="becomeVip" @click="paymentLevel($event, null,null)" 
                    v-if="userVipSort == 0">
                    开通会员
                </div>
                <div class="renew" @click="paymentLevel($event, null, null)" 
                    v-if="userVipSort == 1 || userVipSort == 2">
                    续费
                </div>
                <span class="vipEndTime" v-if="userVipSort == 1 || userVipSort == 2">{{vipEndTime}}到期</span>
            </div>
        </div>
        <div class="user-aboutInformation">
            <div class="infor-title">
                账号管理
            </div>
            <div class="infor-container">
                <div class="infor-content infor-password">
                    <div class="infor-content-left infor-pass-left">
                        <div>账户密码</div>
                        <div v-if="needPassword == 0">已设置，可通过账户密码登录</div>
                        <div v-if="needPassword == 1">未设置，设置密码可以加强账户的安全等级哦</div>
                    </div>
                    <div v-if="needPassword == 1" class="infor-content-right infor-pass-right" @click="changePwd">
                        设置密码&nbsp;&nbsp;>
                    </div>
                    <div v-if="needPassword == 0" class="infor-content-right infor-pass-right" @click="changePwd">
                        修改密码&nbsp;&nbsp;>
                    </div>
                </div>
                <div class="infor-content infor-telphone">
                    <div class="infor-content-left infor-tel-left">
                        <div>手机号码</div>
                        <div>{{telphone | delayTel}}</div>
                    </div>
                    <div class="infor-content-right infor-tel-right" @click="changeTel">
                        修改手机号&nbsp;&nbsp;>
                    </div>
                </div> 
            </div>
        </div>
        <div class="user-aboutNumber">
            <div class="infor-title">
                数据统计
            </div>
            <div class="infor-container">
                <div class="infor-detail-content">
                    <div class="detail-content content-left">
                        <div>累计已生成</div>
                        <div><span>{{create_music_count | delayNum}}</span>首</div>
                    </div>
                    <p></p>
                    <div class="detail-content content-right">
                        <div>累计已下载</div>
                        <div><span>{{download_music_count | delayNum}}</span>首</div>
                    </div>
                </div>
                <div class="infor-detail-content">
                    <div class="detail-content content-left">
                        <div>今日已生成</div>
                        <div><span>{{create_today | delayNum}}</span>首</div>
                    </div>
                    <p></p>
                    <div class="detail-content content-right">
                        <div>今日已下载</div>
                        <div><span>{{download_today | delayNum}}</span>首</div>
                    </div>
                </div>
                <div class="infor-detail-content">
                    <div class="detail-content content-left">
                        <div>今日还可生成</div>
                        <div><span> ∞ </span>首</div>
                    </div>
                    <p class="yigejiange"></p>
                    <div class="detail-content content-right">
                        <div>今日还可下载</div>
                        <div><span>{{download_today_residue | delayNum}}</span>首</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="user-buyRecord">
            <div class="infor-title">
                购买记录
            </div>
            <div class="buyRecord-audioList">
                <div class="audioList-table">
                    <div class="table-thead">
                        <div class="table-thead-iconfont"></div>
                        <div class="table-thead-name">名称</div>
                        <div class="table-thead-remarks">备注</div>
                        <div class="table-thead-keyword">关键词</div>
                        <div class="table-thead-date">购买日期
                            <span @click="changeSort('date')">
                                <i class="iconfont icon-a-Vector388Stroke" 
                                    :style="orderBy != '-createTime' ? 'color: #5490F4;' : ''">
                                </i>
                                <i class="iconfont icon-a-Vector389Stroke" 
                                    :style="orderBy != 'createTime' ? 'color: #5490F4;' : ''">
                                </i>
                            </span>
                        </div>
                        <div class="table-thead-duration">时长
                            <span @click="changeSort('duration')">
                                <i class="iconfont icon-a-Vector388Stroke" 
                                    :style="orderBy != '-duration' ? 'color: #5490F4;' : ''">
                                </i>
                                <i class="iconfont icon-a-Vector389Stroke" 
                                    :style="orderBy != 'duration' ? 'color: #5490F4;' : ''">
                                </i>
                            </span>
                        </div>
                        <div class="table-thead-buyWay">
                            购买方式
                        </div>
                        <div class="table-thead-range">授权范围</div>
                        <div class="table-thead-fileRange">授权书</div>
                        <div class="table-thead-collction">收藏</div>
                        <div class="table-thead-download">
                            音频下载
                            <div class="attention">
                                <img src="../assets/paymentCenter/attention.png"/>
                                <div class="attention-container">
                                    <div class="jiantou"></div>
                                    <div class="attention-detail">
                                        <div>如果您想将已经下载的MP3音频升级为无损品质，可以：</div>
                                        <div>1：选择单曲品质升级</div>
                                        <div>2：选择会员权限升级；当您升级为专业创作者后，所有已下载的MP3音频均可解锁无损品质下载</div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="nothingData" v-if="pageShowContent == 0">用力加载中...</div>
                    <div class="nothingData" v-if="buyRecordArr.length == 0 && pageShowContent == 1">暂时没有购买记录</div>
                    <div class="table-tbody-tr"
                        :class="playerId == item.music_id && playerPayId == item.id? 'playingAudio' : ''"
                        v-for="(item, index) in buyRecordArr" :key="index"
                        @click="setPlayerData(index)">
                        <div class="table-tbody-iconfont">
                            <i class="iconfont icon-liebiaozanting" 
                                v-if="playerId == item.music_id && !playOrNot && playerPayId == item.id"
                                @click.stop="playOrPausedAudio('n')"
                            ></i>
                            <i class="iconfont icon-bofang" 
                                v-if="playerId == item.music_id && playOrNot && playerPayId == item.id"
                                @click.stop="playOrPausedAudio('y')"
                            ></i>
                        </div>
                        <div class="table-tbody-name">
                            <p>
                                {{item.musicName}}
                            </p>
                        </div>
                        <div class="table-tbody-remarks">
                            <span v-if="item.remark == ''">无</span>
                            <span v-if="item.remark != ''">{{item.remark}}</span>
                        </div>
                        <!-- to do js设置标签的宽度 -->
                        <div class="table-tbody-keyword">
                            <div v-if="item.use_type == 'label'">
                                <div class="choosedlabel-content" 
                                    :class="item.keywords.length == 3 
                                    ? 'labelContentThi' : item.keywords.length == 2 
                                    ? 'labelContentSec' : 'labelContentFir'"
                                    v-for="(itemm, indexx) in item.keywords"
                                    :key="indexx"
                                >
                                    {{itemm}}
                                </div>
                            </div>
                  
                            <p class="label-text" 
                                v-if="item.use_type == 'text'">
                                {{item.keywords}}
                            </p>
                        </div>
                        <div class="table-tbody-date">
                            {{item.create_time | delayCreateTime}}
                        </div>
                        <div class="table-tbody-duration">
                            {{item.musicTime | delayDuration}}
                        </div>
                        <div class="table-tbody-buyWay">
                            {{ item.purchase_origin }}
                        </div>
                        <div class="table-tbody-range" :id="'range' + item.music_id">
                            <span class="range" v-if="item.authority == 0">
                                个人非商用
                            </span>
                            <span class="range" v-if="item.authority == 1">
                                商用
                            </span>
                            <span class="range" v-if="item.authority == 9">
                                企业级商用
                            </span>
                            <span class="add" @click.stop="paymentLevel($event, item.music_id, index)">
                                增加授权
                            </span>
                        </div>
                        <div class="table-tbody-fileRange" :id="'fileRange' + item.id" @click.stop="paymentLevel($event, item.id, index)">
                            <span v-if="item.authority_cert == ''">激活音乐授权</span>
                            <span v-if="item.authority_cert != ''">pdf</span>
                        </div>
                        <div class="table-tbody-collction" :id="'collction' + item.music_id">
                            <i class="iconfont" 
                                @click.stop="favoriteOrNot(index, item.is_favorite)"
                                :class="item.is_favorite ? 'icon-icon_leftbar_collect_select_241' 
                                : 'icon-icon_leftbar_collect_unselect_241'"
                                :style="item.is_favorite ? 'color: #d36666;' : ''">

                            </i>
                            <!-- <i :style="item.is_favorite ? 'display:block;': 'display:none;'" 
                                @click.stop="favoriteOrNot(index, 0)"
                                class="iconfont icon-icon_leftbar_collect_select_241" 
                                style="color: #d36666;">
                            </i>
                            <i :style="item.is_favorite ? 'display:none;' : 'displsy:block;'"  
                                @click.stop="favoriteOrNot(index, 1)"
                                class="iconfont icon-icon_leftbar_collect_unselect_241">
                            </i> -->
                        </div>
                        <div class="table-tbody-download" :id="'download'+ item.music_id">
                            <div>
                                <span class="formatFile" style="margin-right:8px;"
                                    @click.stop="downLoadFile('ori_mp3', index)">MP3
                                </span>
                                <span class="addFormat" @click.stop="downLoadFile('wav', index)"> 
                                    WAV 
                                </span>
                            </div>
                            <div>
                                <img src="../assets/audioLoading.gif"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pageContainer" v-if="buyRecordArr.length > 0">
                    <v-container>
                        <v-row justify="center">
                            <v-col cols="8">
                                <v-container class="max-width">
                                <v-pagination
                                id="page-botton"
                                    v-model="pageCurrent"
                                    class="my-4"
                                    circle
                                    :length="pageTatal"
                                ></v-pagination>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </div>
        </div>
        <div @click="logout" class="logout">
            退出登录
        </div>
        <div class="user-player" v-if="playerStatus">
            <div class="player-leftContainer">
                <div class="playOrPause">
                    <i
                        v-if="playOrNot"
                        class="iconfont icon-bofang"
                        @click="playOrPausedAudio('y')"
                        >
                    </i>
                    <i
                        v-if="!playOrNot"
                        class="iconfont icon-liebiaozanting"
                        @click="playOrPausedAudio('n')"
                        >
                    </i>
                </div>
                <div class="audioVolume">
                    <div v-show="sliderShow" class="slider" style="height: 114px">
                        <a-slider vertical :default-value="50" @change="change" @afterChange="afterChange"/>
                    </div>
                    <span @click="showSlider">
                        <i class="iconfont icon-icon_vio_24">
                        </i>
                    </span>
                </div>
                <div class="audioInformation">
                    <div class="audioName">
                        {{playerMusicName}}
                    </div>
                    <div class="playedTime">
                        {{playedTime | delayDuration}} / {{audioDuration | delayDuration}}
                    </div>
                </div>
            </div>
            <div class="player-rightContainer">
                <a-slider  :default-value="0" 
                    :tip-formatter="null" 
                    v-model="playPersent" 
                    @change="changePlayedTime" 
                    @afterChange="afterChangeChangePlayedTime"
                />
            </div>
        </div>
    </div>
</div>
    
</template>

<script>
import qs from 'qs';
import {send_record} from '../utils/umeng_util';
import {axiosGet, axiosPost} from "../../src/axios.js";
import {GETUSERINFORMATION, CHANGEINFORMATION, QUERYPAYMENTHISTORY, FAVORITEORNOT, DOWNLOADFILE} from "../../src/api.js";
import PaymentCenter from './paymentCenter.vue';
export default {
    components: {
        PaymentCenter
    },
    data: () => ({
        playerStatus: false,
        audio: null,
        playerId: -1,
        playerPayId:-1,
        playerMusicName: "这是一个正在播放的音频名字啊",
        sliderShow: false, 
        audioDuration: 0,
        playedTime: 0,
        playPersent: 0, 
        playOrNot: false,

        paymentCenterShow: false,
        paymentForm: '',
        // userAddRange 增加授权  
        // userBecomeVip 开通会员 userRenew 续费 
        // userActivateEmpower激活授权书  userActivateEmpowerNoBusiness userActivateEmpowerBusiness
        
        changeNickNameTip: false,
        needPassword: -1,

        changeHeadTip: false,
        userHeadUrlIndex: -1,
        userHeadUrlArr: [
            "https://download.lazycomposer.com/product/bgmmao/header/1.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/2.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/3.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/4.jpeg",
            "https://download.lazycomposer.com/product/bgmmao/header/5.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/6.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/7.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/8.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/9.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/10.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/11.jpg",
            "https://download.lazycomposer.com/product/bgmmao/header/12.jpeg"
        ],
        userVipSort: -1, //用户级别
        vipEndTime: "",
        userHeadUrl: '',
        nickname: "nickname",
        create_music_count: 0,
        download_music_count: 0,
        download_today: 0,
        download_today_residue: 0,
        create_today: 0,
        telphone: '',
        emailAdr: 'liuwj@lazycomposer.com',

        buyRecordArr: [],
        pageShowContent: -1, // 0加载中 1加载结束
        pageCurrent: 1,
        pageTatal: -1,

        transferMusicName: null,
        transferPaymentMusicId: null,
        orderBy: '-createTime',
        scrollTop:0
    }),
    filters: {
        delayNum(index) {
            if(index < 10 && index != 0) {
                return '0' + index
            }else if(index == 0) {
                return '0' + 0
            } else {
                return index
            }
        },
        delayTel(index) {
            return index.substring(0,3) + " **** " + index.substring(7,11)
        },
        delayCreateTime(createTime) {
            let date = new Date(Number(createTime * 1000))
            return date.getFullYear() + "/" + 
                ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1)  : '0' + (date.getMonth() + 1) )  + "/" + 
                (date.getDate() > 9 ? date.getDate() : '0' + date.getDate() ) 
        },
        delayDuration (dur) {
            const duration = Math.round(dur)
            const min = Math.floor(duration / 60)
            const sec = (duration % 60) > 9 ? (duration % 60) : '0' + (duration % 60)
            return min + ':' + sec
        }
    },
    watch: {
        pageCurrent: function(val, oldVal) {
            this.queryPaymentHistory(val);
        },
        paymentCenterShow: function(val, oldVal) {
            if(val) {
                if(this.paymentForm != 'userActivateEmpowerNoBusiness' || this.paymentForm != 'userActivateEmpowerBusiness'){
                    // userAddRange 增加授权  
                    // userBecomeVip 开通会员 userRenew 续费 
                    send_record("pay_center_access",
                        {
                            origin: window.location.pathname.slice(1),
                            type: this.paymentForm == "userAddRange" ? 'add_auth' 
                                : this.paymentForm == "userRenew" ? "recharge" : "buy_vip"
                        }
                    );
                }
                this.$refs.paymentCont.reSetAllData();
                document.documentElement.style.overflow = "hidden";
            } else {
                document.documentElement.style.overflow = "scroll";
            }
        }
    },
    mounted() {
        window.addEventListener("scroll", this.getScrollTop, true)
    },
     activated(){
        this.buyRecordArr = []
        this.pageShowContent = 0;
        this.getUserInformation();
        this.queryPaymentHistory(this.pageCurrent);
    },
    methods: {
        getScrollTop() {
            this.scrollTop = document.documentElement.scrollTop;
        },
        changeSort(str) {
            if(str == 'date') {
                if(this.orderBy == 'createTime' || this.orderBy == 'duration' || this.orderBy == '-duration') {
                    this.orderBy = '-createTime';
                }else {
                    this.orderBy = "createTime";
                }
            }else if(str == 'duration') {
                if(this.orderBy == 'duration' || this.orderBy == 'createTime' || this.orderBy == '-createTime') {
                    this.orderBy = '-duration';
                }else {
                    this.orderBy = "duration";
                }
            }
            this.pageShowContent = 0;
            this. buyRecordArr = [];
            this.queryPaymentHistory()
        },
        queryPaymentHistory(pageCurrent) {
            let data = {
                size: 8,
                page: pageCurrent,
                orderBy: this.orderBy
            }
            axiosGet(QUERYPAYMENTHISTORY, data)
                .then(res => {
                    this.pageShowContent = 1;
                    this.pageTatal = Math.ceil(res.paginate.total / 8)
                    let data = res.data;
                    this.buyRecordArr = data;
                    console.log(res.data)
                }).catch(err => {
                    console.log("query payment history error", err)
                })
        },
        favoriteOrNot(index, status) {
            this.buyRecordArr[index].is_favorite = !this.buyRecordArr[index].is_favorite
            let data = {
                musicId: this.buyRecordArr[index].music_id,
                status: status ? 0 : 1,
            };
            axiosPost(FAVORITEORNOT, qs.stringify(data))
                .then((res) => {
                    status == 0 
                    ? send_record("collect_cancel_forever", {origin: "user_list"}) 
                    : send_record("collect_forever", {origin: "user_list"});
                    console.log(status == 0 ? "取消收藏成功" : "收藏成功")
                })
                .catch((err) => {
                    console.log(status == 0 ? "取消收藏失败" : "收藏失败")
                });
        },
        downLoadFile(str, index) {
            if(this.sliderShow) {
                this.sliderShow = false;
            }
            let loadingDom = document.getElementsByClassName("table-tbody-download")[index].children;
            loadingDom[0].style.display = "none";
            loadingDom[1].style.display = "block";
            let data = {
                musicId: this.buyRecordArr[index].music_id,
                type: str
            }
            axiosPost(DOWNLOADFILE, qs.stringify(data))
            .then((res) => {
                if (res.data.path != null) {
                    loadingDom[1].style.display = "none";
                    loadingDom[0].style.display = "block"; 
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.href = res.data.path;
                    a.download = this.buyRecordArr[index].musicName + "." + (str == 'MP3' ? 'mp3' : 'wav');;
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(res.data.path);
                }
            })
            .catch((err) => {
                loadingDom[1].style.display = "none";
                loadingDom[0].style.display = "block"; 
                if (err.data.code == -1) {
                    this.$message.error(err.data.msg);
                } else {
                    this.$message.error( "未知错误,请重新尝试!");
                }
            });
        },
        // 给音频设置音量
        change(value) {
            this.audio.volume = (value / 100);
            window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
        },
        // 音量设置结束，滑块消失
        afterChange() {
            this.sliderShow = false;
        },
        queryClick(e) {
            if(!this.sliderShow){
                return;
            }
            let evt = event || window.event;
            let path = evt.composedPath && evt.composedPath();
            let showFlag;
            if(this.sliderShow) {
                for(let i = 0; i < path.length; i++) {
                    if(path[i].className.indexOf("slider") > -1){
                        showFlag = true;
                        break;
                    } else if(path[i].className == ''){
                        showFlag = false;
                        break;
                    }else {
                        showFlag = false;
                    }
                }
                this.sliderShow = showFlag;
            } 
        },
        changePlayedTime(value) {
            this.audio.pause();
            this.audio.currentTime = value / 100 * this.audioDuration;
        },
        afterChangeChangePlayedTime() {
            if(this.playOrNot) {
                this.audio.play()
            }else {
                this.audio.pause()
            }
        },
        // 音量控制滑块是否显示
        showSlider() {
            if (!this.sliderShow) {
                this.sliderShow = true;
            } else if (this.sliderShow) {
                this.sliderShow = false;
            }
            window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
        },
        closePaymentCenter() {
            this.paymentCenterShow = false;
            this.downloadAudioLoadingFlag(true, this.transferPaymentMusicId);
        },
        payAudioLoadingOver(loadingOverId) {
            if(loadingOverId == -100) {
                this.downloadAudioLoadingFlag(false, this.transferPaymentMusicId);
            }
            this.downloadAudioLoadingFlag(false, loadingOverId);
        },
        downloadAudioLoadingFlag(flag, downloadAudioId) {
            let dom = document.getElementById("download" + downloadAudioId).children;
            if(flag) {
                dom[0].style.display = "none";
                dom[1].style.display = "block"; 
            } else {
                dom[1].style.display = "none";
                dom[0].style.display = "block";
            }
            
        },
        setCurrentMusicLicense(id, url) {
            let dom = document.getElementById("fileRange" + id)
            if(url == null) {
                dom.innerText = 'pdf';
            } else {
                dom.innerText = 'pdf';
                let attr = document.createAttribute('title');
                attr.value = url;
                dom.setAttributeNode(attr); 
            }
        },
        paymentLevel(e, id, index) {
            this.transferPaymentMusicId = null;
            this.transferMusicName = null;
            this.paymentForm = '';
            let str = e.target.innerText;
            if(str == "增加授权") {
                this.paymentForm = "userAddRange";
                this.transferPaymentMusicId = id;
                this.paymentCenterShow = true;
                this.transferMusicName = this.buyRecordArr[index].musicName;

                send_record("add_auth_button");
            } else if(str == "开通会员") {
                this.paymentForm = "userBecomeVip";
                this.paymentCenterShow = true;
            } else if(str == "续费"){
                this.paymentForm = "userRenew"
                this.paymentCenterShow = true;
            } else if(str == "pdf") {
                if(e.target.title == '') {
                    this.downLoadPDF(this.buyRecordArr[index].musicName,this.buyRecordArr[index].authority_cert)
                }else {
                    this.downLoadPDF(this.buyRecordArr[index].musicName, e.target.title);
                }
            } else if(str == "激活音乐授权") {
                this.paymentForm = this.buyRecordArr[index].authority == 0 
                ? 'userActivateEmpowerNoBusiness' : 'userActivateEmpowerBusiness';
                this.transferPaymentMusicId = id;
                this.paymentCenterShow = true;
            }
        },
        downLoadPDF(musicName, url) {
            const downLoad = async () => {
                const responsePromise = await fetch(url)
                const blob = await responsePromise.blob()
                const objectURL = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = objectURL
                a.download = musicName + "授权文件.pdf"
                a.click()
                a.remove()
            }
            downLoad()
        },
        pauseAudio() {
            if(this.audio == null)
                return
            if(this.playOrNot) {
                this.audio.pause()
                this.playOrNot = false;
            }
        },
        playOrPausedAudio(str) {
            if(str == 'n') {
                this.playOrNot = true;
                this.audio.play()
            } else if(str == 'y') {
                this.playOrNot = false;
                this.audio.pause()
            }
        },
        closeContent() {
            this.changeHeadTip = false;
        },
        openContent() {
            this.changeHeadTip = true;
            for(let i = 0; i < this.userHeadUrlArr.length; i++) {
                if(this.userHeadUrl == this.userHeadUrlArr[i]) {
                    this.userHeadUrlIndex = i;
                }
            }
        },
        chooseHeadUrl(index) {
            this.userHeadUrlIndex = index;
        },
        setPlayerData(index) {
            this.playerId = this.buyRecordArr[index].music_id;
            this.playerPayId = this.buyRecordArr[index].id
            this.playerStatus = true;
            this.playerMusicName = this.buyRecordArr[index].musicName
            this.audio = document.createElement("audio");
            this.audio.src = this.buyRecordArr[index].musicDemoFilePath
            this.audio.addEventListener("canplay", ()=> {
                this.audioDuration = parseInt(this.audio.duration);
            })
            this.audio.addEventListener("timeupdate", ()=> {
                this.playedTime = this.audio.currentTime;
                this.playPersent = this.audio.currentTime / this.audioDuration * 100;
                    if(this.audio.currentTime == this.audioDuration) {
                    this.playFlag = false;
                    this.audio.currentTime = 0;
                    this.playPersent = 0;
                }
            })
        },
        determineHeadUrl() {
            let data = {
                phone: this.telphone,
                headUrl: this.userHeadUrlArr[this.userHeadUrlIndex]
            }
            axiosPost(CHANGEINFORMATION, qs.stringify(data))
            .then((res) => {
                console.log("更换头像成功");
                this.getUserInformation();
            }).catch( err => {
              console.log("更换头像失败", err)
            })
            this.changeHeadTip = false;
        },
        uploadUserHeadUrl(e) {
            // console.log(this.$refs.uploadUserHeadUrl.files[0])
        },
        getUserInformation() {
            axiosGet(GETUSERINFORMATION)
                .then(res => {
                    this.needPassword = res.data.needPassword;
                    this.userHeadUrl = res.data.headerUrl;
                    this.telphone  = res.data.phone
                    this.nickname = res.data.nickname
                    this.create_music_count = res.data.create_music_count
                    this.download_music_count = res.data.download_music_count
                    this.download_today = res.data.download_today
                    this.download_today_residue = res.data.download_today_residue
                    this.create_today = res.data.create_today
                    this.userVipSort = res.data.vip
                    this.vipEndTime = res.data.vip_end_time
                }).catch(err => {
                    this.$message.error(err.data.msg);
                    if(err.data.code == -1) {
                        // TODO:此时应该弹出登录
                    }
                })
        },
        logout()  {
            // 退出登录
            this.$emit("userLogOutSystem");
        },
        changePwd() {
            // 修改密码
            this.$emit("userChangePwd", this.needPassword, this.telphone);
        },
        changeTel() {
            // 修改手机号
            this.$emit("userChangeTel", this.telphone);
        },
        getFocus() {
            this.changeNickNameTip = true;
            this.$nextTick(()=> {
                this.$refs.input.focus();
                this.$refs.input.select();
            })
        },
        getEnter(e) {
            if(e.key == "Enter") {
                this.changeUserName()
            }
        },
        changeUserName() {
            this.changeNickNameTip  = false;
            let data = {
                phone: this.telphone,
                nickname: this.nickname
            }
            axiosPost(CHANGEINFORMATION, qs.stringify(data))
                .then((res) => {
                    send_record("user_change_nickname");
                    this.getUserInformation()
                }).catch( err => {
                    if(err.data.code == -1) {
                        this.$message.error(err.data.msg);
                    } else {
                        this.$message.error("昵称修改失败,请重新尝试!");
                    }
                    this.getUserInformation()
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.paymentContainer{
    position: absolute;
    width: 100vw;
    min-width: 1440px;
    height: 100vh;
    min-height: 700px;
}
.headUrl-content{
    width: 100vw;
    min-width: 1440px;
    height: 100vh;
    min-height: 900px !important;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    .overlay-background{
        width: 100vw;
        min-width: 1440px;
        height: 100vh;
        min-height: 900px;
        background: #000;
        opacity: 0.5;
    }
    .headContent{
        width: 612px;
        height: 352px;
        background: #FFFFFF;
        opacity: 1;
        border-radius: 12px;
        position:absolute;
        margin:auto;
        top:0;
        left:0;
        right:0;
        bottom:0;
        .headContent-title{
            height: 58px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 58px;
            letter-spacing: 0.025em;
            color: #000000;
            padding-left: 36px;
            padding-right: 36px;
            border-bottom: 1px solid #E5E5E5;
            span:nth-child(2) {
                cursor: pointer;
                float: right;
                color: #AAAFBC;
                font-size: 30px;
                font-weight: 350;
            }
        }
        .headContent-image{
            display: flex;
            margin-top: 50px;
            margin-left: 36px;
            .user-image{
                width: 100px;
                img{
                    width: 100px;
                    height: 100px;
                    cursor: pointer;
                    border-radius: 100%;
                }
                p{
                    width:100px;
                    text-align: center;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 131%;
                    letter-spacing: 0.025em;
                    color: #B8B8B8;
                    margin-top: 8px;
                }
            }
            .headContent-imageArr{
                margin-left: 60px;
                width: 440px;
                display: flex;
                flex-wrap: wrap;
                img{
                    cursor: pointer;
                    margin-right: 10px;
                    width: 55px;
                    height: 55px;
                    border-radius: 2px;
                }
            }
        }
        .button-content{
            display: flex;
            float: right;
            margin-right: 36px;
            margin-top: 35px;
            span{
                cursor: pointer;
                display: inline-block;
                width: 118px;
                height: 44px;
                border-radius: 20px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 44px;
                display: flex;
                letter-spacing: 0.1px;
                text-align: center;
                padding: 0 40px;
            }
            span:nth-child(1) {
                border: 1px solid #5490F4;
                color: #5490F4;
            }
            span:nth-child(2) {
                border: 1px solid #000;
                background: #000;
                color: #fff;
                margin-left: 8px;
            }
        }
    }
    
}

.user-container{
    margin-left: 88px !important;
    min-width: 1352px !important;
    width: calc(100vw - 88px);
    min-height: 900px !important;
    min-height: 100vh;
    padding-left: 68px !important;
    padding-top: 80px !important;
    padding-bottom: 100px;
    background: #f3f3f3;
    .user-title{
        font-family: PangMenZhengDao;
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 134.5%;
        letter-spacing: 0.025em;
        color: #000000;
    }
    .user-aboutAudioNum{
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        min-width: 1172px !important;
        width: calc(100vw - 268px);
        .user-information{
            display: flex;
            .UserHeadUrl{
                img{
                    cursor: pointer;
                    width: 77px;
                    height: 77px;
                    box-sizing: border-box;
                    border-radius: 100px;
                    border: 3px solid yellowgreen;
                    border: 3px solid #FFFFFF;
                }
            }
            .UserHeadUrl:hover{
                .uploadUserHeadUrl{
                    display:  block;
                }
            }
            .uploadUserHeadUrl{
                display: none;
                margin-top: -100px;
                width: 100px;
                height: 100px;
                background: #000000;
                opacity: 0.67;
                border-radius: 100px;
                text-align: center;
                color: #fff;
                .file{
                    width: 100px;
                    height: 100px;
                    border: 1px dashed black;
                    margin-top: 0px;
                    opacity: 0;
                    position: relative;
                    z-index: 99;
                }
                i{
                    font-size: 30px;
                    position: relative;
                    top: -90px;
                }
                p{
                    position: relative;
                    top: -90px;
                }
            }
            .maxTop{
                margin-top: 25px;
            }
            .minTop{
                margin-top: 8px;
            }
            .userNickname{
                margin-left: 20px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 34px;
                align-items: center;
                letter-spacing: 0.025em;
                color: #000000;
                .userNickname-model{
                    width: 160px;
                    height: 30px;
                    .p{
                        white-space:nowrap;
                        text-overflow:ellipsis; 
                        overflow:hidden;
                        max-width: 130px;
                        display: inline-block;
                    }
                    i{
                        position: relative;
                        top: -10px;
                        cursor: pointer;
                        color: #B8B8B8;
                    }
                }
                .userNickname-input{
                    width: 160px;
                    height: 30px;
                    input{
                        max-width: 130px;
                        border:  none;
                        outline: none;
                    }
                    i{
                        color: #B8B8B8;
                        cursor: pointer;
                        margin-left: 8px;
                    }
                }
                .userSort{
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    align-items: center;
                    letter-spacing: 0.025em;
                    padding: 2px 4px;
                    color: rgba(255, 255, 255, 0.8);
                    display: inline;
                    border-bottom-right-radius: 8px;
                    border-top-left-radius: 8px;
                }
            }
        }
        .vipContainer{
            div{
                cursor: pointer;
                padding: 8px 49.5px;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                align-items: center;
                letter-spacing: 0.025em;
                color: #000000;
                opacity: 0.8;
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                height: 38px;
            }
            .becomeVip{
                margin-top: 21px;
            }
            .renew{
                margin-top: 10.5px;
            }
            .vipEndTime{
                width: 132px;
                display: inline-block;
                margin-top: 4px;
                text-align: center;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                letter-spacing: 0.025em;
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }
    .user-aboutInformation{
        margin-top: 50px;
        border-radius: 12px;
        min-width: 1172px !important;
        width: calc(100vw - 268px);
        .infor-title{
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 134.5%;
            letter-spacing: 0.025em;
            color: #000000;
        }
        .infor-container{
            display: flex;
            justify-content: space-between;
        }
        .infor-content{
            min-width: 580px !important;
            width: calc((100vw - 278px) / 2);
            display: flex;
            height: 80px;
            background: #FFFFFF;
            padding: 17px 40px;
            border-radius: 12px;
            margin-top:20px;
            justify-content:space-between;
            .infor-content-left{
                div:nth-child(1) {
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    letter-spacing: 0.025em;
                    color: #434343;
                }
                div:nth-child(2) {
                    margin-top:  12px;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 131%;
                    letter-spacing: 0.025em;
                    color: #B8B8B8;
                }
            }
            .infor-content-right{
                cursor: pointer;
                font-family: PingFang SC;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 40px;
                align-items: center;
                letter-spacing: 0.025em;
                color: #999;
            }
        }
    }
    .user-aboutNumber{
        min-width: 1172px !important;
        width: calc(100vw - 268px);
        margin-top: 50px;
        .infor-title{
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 134.5%;
            letter-spacing: 0.025em;
            color: #000000;
        }
        .infor-container{
            min-width: 1172px !important;
            width: calc(100vw - 268px);
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            .infor-detail-content{
                min-width: 381px !important;
                width: calc((100vw - 288px) / 3);
                height: 144px;
                background: #FFFFFF;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                padding: 35px 38px;
                text-align: center;
                .detail-content{
                    width: calc(((100vw - 288px) / 3 - 2px / 2));
                    div:nth-child(1) {
                        font-family: PingFang SC;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 134.5%;
                        letter-spacing: 0.025em;
                        color: #999999;
                    }
                    div:nth-child(2) {
                        margin-top: 12px;
                        text-align: center;
                        font-family: PingFang SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 131%;
                        letter-spacing: 0.025em;
                        color: #B8B8B8;
                        span{
                            font-family: PingFang SC;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 32px;
                            line-height: 45px;
                            letter-spacing: 0.025em;
                            color: #000000;
                            color: #5490f4;
                            margin-right: 8px;
                        }
                    }
                }
                p{
                    margin: 0 20px;
                    width: 1px;
                    height: 74px;
                    background: #E5E5E5;
                }
                .yigejiange{
                    margin: 0 22px 0 22px;
                }
            }
        }
    }
    .user-buyRecord{
        min-width: 1172px !important;
        width: calc(100vw - 268px);
        margin-top: 54px;
        .infor-title{
            font-family: PingFang SC;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 134.5%;
            letter-spacing: 0.025em;
            color: #000000;
        }
        .buyRecord-audioList{
            background: #FFFFFF;
            padding-bottom: 14px;
            border-radius: 12px;
            margin-top: 20px;
            .audioList-table{
                .table-thead{
                    display: flex;
                    justify-content: space-between;
                    height: 80px;
                    padding: 0 40px;
                    border-bottom: 1px #b8b8b8 solid;
                    margin-bottom: 8px;
                    font-family: PingFang SC;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.025em;
                    color: #999999;
                    >div{
                        text-align: center;
                        line-height: 72px;
                    }
                    .table-thead-iconfont{
                        min-width: 24px;
                        margin-right: 16px;
                        // background: #2FAA4A;
                    }
                    .table-thead-name{
                        min-width: 100px;
                        width: calc((100 / 1172) * (100vw - 268px));
                        // background: goldenrod;
                        text-align: left;
                    }
                    .table-thead-remarks{
                        min-width: 90px;
                        width: calc((90 / 1172) * (100vw - 268px));
                        // background: rgb(91, 77, 43);
                    }
                    .table-thead-keyword{
                        min-width: 179px;
                        width: calc((179 / 1172) * (100vw - 268px));
                        // background: rgb(173, 150, 96);
                    }
                    .table-thead-date{
                        min-width: 132px;
                        // background: indianred;
                        span{
                            cursor: pointer;
                            margin-left: -6px;
                            i{
                                font-size: 14px;
                            }
                            i:nth-child(2) {
                                margin-left: -6px;
                            }
                        }
                    }
                    .table-thead-duration{
                        min-width: 62px;
                        // background: saddlebrown;
                        span{
                            cursor: pointer;
                            margin-left: -6px;
                            i{
                                font-size: 14px;
                            }
                            i:nth-child(2) {
                                margin-left: -6px;
                            }
                        }
                    }
                    .table-thead-buyWay{
                        min-width: 95px;
                        // background: #18253B;
                        span{
                            margin-left: -6px;
                            color: #5490F4;
                            i{
                                font-size: 14px;
                            }
                            i:nth-child(2) {
                                margin-left: -6px;
                            }
                        }
                    }
                    .table-thead-range{
                        min-width: 154px;
                        // background: palegreen;
                    }
                    .table-thead-fileRange{
                        min-width: 74px;
                        // background: #2FAA4A;
                    }
                    .table-thead-collction{
                        min-width: 34px;
                        // background: #439154;
                    }
                    .table-thead-download{
                        min-width: 94px;
                        // background: #14431E;
                        .attention{
                            text-align: left;
                            display: inline;
                            position: relative;
                            z-index: 3 !important;
                            img{
                                margin-top: -4px;
                                width: 18px;
                                height: 18px;
                            }
                            .attention-container{
                                position: absolute;
                                left: -7px;
                                display: none;
                                margin-top: -20px;
                                font-family: 'PingFang SC';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                letter-spacing: 0.025em;
                                color: rgba(255, 255, 255, 0.9);
                                .jiantou{
                                    width: 0;
                                    height: 0;
                                    border-bottom: 14px solid rgba(0, 0, 0, 0.8);
                                    border-right: 14px solid transparent;
                                    border-left: 14px solid transparent;
                                }
                                .attention-detail{
                                    margin-left: -370px;
                                    padding: 14px 16px;
                                    width: 491px;
                                    height: 108px;
                                    background: rgba(0, 0, 0, 0.8);
                                    border-radius: 16px;
                                }
                            }
                        }
                        .attention:hover{
                            cursor: pointer;
                            .attention-container{
                                display: block;
                                z-index: 3 !important;
                            }
                        }
                    }
                }
                .nothingData{
                    padding: 28px 0px;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    height: 120px;
                    text-align: center;
                    letter-spacing: 0.025em;
                    color: #999999;
                }
                .table-tbody-tr{
                    display: flex;
                    justify-content: space-between;
                    height: 60px;
                    color: rgba(0, 0, 0, 0.8);
                    padding: 0 40px;
                    >div{
                        line-height: 60px;
                        font-family: PingFang Sc;
                        font-style: Regular;
                        font-size: 14px;
                        letter-spacing: 2.5%;
                        text-align: center;
                    }
                    .table-tbody-iconfont{
                        min-width: 24px;
                        margin-right: 16px;
                        i{
                            cursor: pointer;
                            font-size: 24px;
                        }
                    }
                    .table-tbody-name{
                        text-align: left;
                        min-width: 100px;
                        width: calc((100 / 1172) * (100vw - 268px));
                        // background: goldenrod;
                        p{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            height: 60px;
                        }
                    }
                    .table-tbody-remarks{
                        min-width: 90px;
                        width: calc((90 / 1172) * (100vw - 268px));
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        color: rgba(0, 0, 0, 0.3);
                        // background: rgb(91, 77, 43);
                    }
                    .table-tbody-keyword{
                        min-width: 179px;
                        width: calc((179 / 1172) * (100vw - 268px));
                        // background: rgb(173, 150, 96);
                        >div{
                            justify-content: center;
                            display: flex;
                        }
                        .choosedlabel-content{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            margin-top: 16px;
                            border: 1px solid rgba(0, 0, 0, 0.16);
                            box-sizing: border-box;
                            border-radius: 12px;
                            height: 24px;
                            line-height: 24px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 131%;
                            display: flex;
                            align-items: center;
                            letter-spacing: 0.025em;
                            color: #666666;
                            padding:0 10px;
                        }
                        .labelContentSec:nth-child(2){
                            margin-left:4px;
                        }
                        .labelContentThi:nth-child(2){
                            margin: 16px 4px;
                        }
                        .label-text{
                            min-width: 179px;
                            width: calc((179 / 1172) * (100vw - 268px));
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-family: 'PingFang SC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            letter-spacing: 0.025em;
                            color: rgba(0, 0, 0, 0.8);
                        }
                    }
                    .table-tbody-date{
                        min-width: 132px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        // background: indianred;
                    }
                    .table-tbody-duration{
                        min-width: 62px;
                        // background: saddlebrown;
                    }
                    .table-tbody-buyWay{
                        min-width: 95px;
                        // background: #5490f4;
                    }
                    .table-tbody-range{
                        min-width: 154px;
                        // background: palegreen;
                        .add{
                            cursor: pointer;
                            line-height: 14px;
                            font-size: 12px;
                            color: #5490F4;
                            border-bottom: 1px solid #5490F4;
                        }
                    }
                    .table-tbody-fileRange{
                        width: 74px;
                        color: #5490F4;
                        text-decoration-line: underline;
                        text-align: center;
                        // background: #2FAA4A;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        letter-spacing: 0.025em;
                        
                    }
                    .table-tbody-collction{
                        min-width: 34px;
                        // background: #439154;
                    }
                    .table-tbody-download{
                        width: 94px;
                        overflow: hidden;
                        text-align: center;
                        color: #5490F4;
                        // background: #439154;
                        >div:nth-child(1) {
                            span{
                                text-decoration-line: underline;
                            } 
                        }
                        >div:nth-child(2) {
                            display: none;
                            img{
                                width: 45px;
                            }
                        }
                        
                    }
                }
                .table-tbody-tr:hover{
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.08);
                }
                .playingAudio{
                    background: rgba(0, 0, 0, 0.08);
                }
            }
            .pageContainer{
                height: 36px;
                margin-top: 26px;
                
                overflow: hidden;
                >.container{
                    margin-top: -45px;
                }
            }
        }
    }
    .logout{
        cursor: pointer;
        margin-top: 54px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.025em;
        border: 1px solid #B8B8B8;
        text-align: center;
        border-radius: 24px;
        line-height: 34px;
        width: 120px;
        height: 34px;
        color: #999999;
    }
    .user-player{
        position: fixed;
        bottom: 0px;
        min-width: 1352px !important;
        width: calc(100vw - 88px);
        margin-left: -68px;
        margin-top: 19px;
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px -5px 41px rgba(10, 38, 81, 0.07);
        height: 93px;
        display: flex;     
        justify-content: space-between;
        padding: 0px 113px 0 67px;
        i{
            cursor: pointer;
        }
        .player-leftContainer{
            display: flex;
            .playOrPause{
                width: 106px;
                height: 46px;
                line-height: 46px;
                background: rgba(84, 144, 244, 0.07) !important;
                border-radius: 21px;
                margin-top: 18.5px;
                text-align: center;
                i{
                    font-size: 24px;
                }
            }
            .audioVolume{
                margin-left: 16px;
                position: relative;
                .slider {
                    top: -95px;
                    padding-bottom: 10px;
                    position: absolute;
                    margin-left: -3px;
                    margin-top: 10px;
                    background: #ffffff;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                    border-radius: 5px;
                }
                span{
                    margin-top: 29.5px;
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    i{
                        font-size: 24px;
                        line-height: 24px;
                    }
                }
            }
            .audioInformation{
                margin-left: 12px;
                margin-top: 18.5px;
                .audioName{
                    width: 244px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 128.5%;
                    letter-spacing: 0.025em;
                    color: #434343;
                }
                .playedTime{
                    font-family: 'PingFang SC';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(0, 0, 0, 0.4);
                }
            }
        }
        .player-rightContainer{
            width: 67.3%;
            height: 93px;
        }
    }
}

</style>

<style>
.player-rightContainer .ant-slider{
    margin: 0;
    padding: 0;
    margin-top: 37px;
}
.player-rightContainer  .ant-slider-track {
  background: #000 !important;
}
.player-rightContainer .ant-slider-step{
    background-color: rgba(0,0,0,0.2) !important;
}
.player-rightContainer  .ant-slider-handle {
    width: 8px;
    height: 18px;
    background: #000000;
    border-radius: 15px;
    border: none;
    margin-top: -6px !important;
}

</style>

<style>
.pageContainer .v-pagination__item{
  background: #f9f9f9 !important;
  box-shadow: none !important;
}
.pageContainer .v-pagination__navigation{
  background: #f9f9f9 !important;
  box-shadow: none !important;
}
.pageContainer #page-botton .primary{
  background: #000 !important;
}
.pageContainer #page-botton li{
  box-shadow: none !important;
}
</style>